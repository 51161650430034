<template>
  <div>
    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">{{ $t('configuration.settings.title') }}<br /></h2>
      </div>
    </b-card>

    <!-- <b-card>
      <b-card-header class="p-0">
        <h4>{{ $t('configuration.settings.otp_title') }}</h4>
      </b-card-header>
      <hr>
      <b-card-body>
        <b-row>
          <b-col md="12">
            <div>
              <b-form-checkbox v-model="form.accept" variant="primary" switch>
                {{form.accept && form.accept == true ? $t('configuration.settings.otp_checkbox_disabled') : $t('configuration.settings.otp_checkbox_enabled') }}
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <div class="d-flex flex-row-reverse mt-1">
        <b-button variant="primary" @click="updateConfigurations()" :disabled="loadingUpdateConfig == true">
          <feather-icon v-if="loadingUpdateConfig == false" icon="SaveIcon" /><b-spinner
            v-if="loadingUpdateConfig == true" small /> &nbsp; {{ $t('button.edit_form') }}
        </b-button>
      </div>
    </b-card> -->
    <!-- Activer ou désactiver la gestion des concours -->
    <div v-if="!isAdmin">
      <h3 class="ml-2">{{ $t('configuration.settings.no_access') }}</h3>
    </div>
    <div v-else>
      <b-card>
        <b-card-header class="p-0">
          <h4>{{ $t('configuration.settings.concours_title') }}</h4>
        </b-card-header>
        <hr />
        <b-card-body>
          <b-row>
            <b-col md="12">
              <div>
                <b-form-checkbox
                  v-model="form.concours"
                  variant="primary"
                  switch
                >
                  {{
                    form.concours && form.concours == true
                      ? $t('configuration.settings.concours_checkbox_disabled')
                      : $t('configuration.settings.concours_checkbox_enabled')
                  }}
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
        <div class="d-flex flex-row-reverse mt-1">
          <b-button
            variant="primary"
            :disabled="loadingUpdateConfig == true"
            @click="updateConfigurations()"
          >
            <feather-icon
              v-if="loadingUpdateConfig == false"
              icon="SaveIcon"
            /><b-spinner v-if="loadingUpdateConfig == true" small /> &nbsp;
            {{ $t('button.edit_form') }}
          </b-button>
        </div>
      </b-card>
      <b-card>
        <b-card-header class="p-0">
          <h4>Configuration des horaires de visite</h4>
        </b-card-header>
        <hr />
        <b-card-body>
          <div class="d-flex align-items-center">
            <b-form-group
              label="Heure de Début"
              label-cols-sm="5"
              label-cols-lg="5"
            >
              <flat-pickr
                v-model="heureDebutVisite"
                class="form-control"
                placeholder="Veuillez saisir l’heure de début de la visite"
                :config="{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'H:i',
                  minuteIncrement: 1,
                }"
              />
            </b-form-group>
            <b-form-group
              label="Heure de Fin"
              label-cols-sm="5"
              label-cols-lg="5"
              class="ml-2"
            >
              <flat-pickr
                v-model="heureFinVisite"
                class="form-control"
                placeholder="Veuillez saisir l’heure de début de la visite"
                :config="{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'H:i',
                  minuteIncrement: 1,
                }"
              />
            </b-form-group>
          </div>
        </b-card-body>
      </b-card>
      <b-card>
        <b-card-header class="p-0">
          <h4>{{ $t('configuration.settings.delay_title') }}</h4>
        </b-card-header>
        <hr />
        <b-card-body>
          <b-row>
            <b-col md="12">
              <div>
                <div>
                  <app-collapse>
                    <app-collapse-item
                      :title="'Etablissement d\'hébergement touristique'"
                      class="brdr my-1"
                    >
                      <hr />
                      <div>
                        <b-card-header>
                          <strong
                            >- Demande d’autorisation d’exploitation et de
                            premier classement</strong
                          >
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEH_autorisation_premier_classement
                                      .control_form
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEH_autorisation_premier_classement
                                        .notification_durations,
                                      form.UEH_autorisation_premier_classement
                                        .control_form,
                                      form.UEH_autorisation_premier_classement
                                        .id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_tech')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEH_autorisation_premier_classement
                                      .control_tech
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEH_autorisation_premier_classement
                                        .notification_durations,
                                      form.UEH_autorisation_premier_classement
                                        .control_tech,
                                      form.UEH_autorisation_premier_classement
                                        .id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="$t('configuration.settings.audit')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEH_autorisation_premier_classement
                                      .audit
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.audit_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEH_autorisation_premier_classement
                                        .notification_durations,
                                      form.UEH_autorisation_premier_classement
                                        .audit,
                                      form.UEH_autorisation_premier_classement
                                        .id,
                                      2
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong>- Demande de reclassement</strong>
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEH_reclassement.control_form"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEH_reclassement
                                        .notification_durations,
                                      form.UEH_reclassement.control_form,
                                      form.UEH_reclassement.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_tech')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEH_reclassement.control_tech"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEH_reclassement
                                        .notification_durations,
                                      form.UEH_reclassement.control_tech,
                                      form.UEH_reclassement.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="$t('configuration.settings.audit')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEH_reclassement.audit"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.audit_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEH_reclassement
                                        .notification_durations,
                                      form.UEH_reclassement.audit,
                                      form.UEH_reclassement.id,
                                      2
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong>- Notifications diverses</strong>
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEH_notification.control_form"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEH_notification
                                        .notification_durations,
                                      form.UEH_notification.control_form,
                                      form.UEH_notification.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_tech')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEH_notification.control_tech"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEH_notification
                                        .notification_durations,
                                      form.UEH_notification.control_tech,
                                      form.UEH_notification.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="$t('configuration.settings.audit')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEH_notification.audit"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.audit_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEH_notification
                                        .notification_durations,
                                      form.UEH_notification.audit,
                                      form.UEH_notification.id,
                                      2
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                    </app-collapse-item>
                  </app-collapse>
                </div>

                <div>
                  <app-collapse>
                    <app-collapse-item
                      :title="'Agence de Voyage'"
                      class="brdr my-1"
                    >
                      <hr />
                      <div>
                        <b-card-header>
                          <strong>- Demande de licence</strong>
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="6">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEAV_licence.control_form"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEAV_licence.notification_durations,
                                      form.UEAV_licence.control_form,
                                      form.UEAV_licence.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group
                                :label="$t('configuration.settings.meet_tech')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEAV_licence.meet_tech"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.meet_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEAV_licence.notification_durations,
                                      form.UEAV_licence.meet_tech,
                                      form.UEAV_licence.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong>- Demande d’agrément de succursale</strong>
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="6">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEAV_agrement.control_form"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEAV_agrement.notification_durations,
                                      form.UEAV_agrement.control_form,
                                      form.UEAV_agrement.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group
                                :label="$t('configuration.settings.meet_tech')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEAV_agrement.meet_tech"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.meet_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEAV_agrement.notification_durations,
                                      form.UEAV_agrement.meet_tech,
                                      form.UEAV_agrement.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong
                            >- Demande de renouvellement de licence ou agrément
                            de succursale</strong
                          >
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="6">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEAV_renew_agrement_licence
                                      .control_form
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEAV_renew_agrement_licence
                                        .notification_durations,
                                      form.UEAV_renew_agrement_licence
                                        .control_form,
                                      form.UEAV_renew_agrement_licence.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group
                                :label="$t('configuration.settings.meet_tech')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEAV_renew_agrement_licence.meet_tech
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.meet_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEAV_renew_agrement_licence
                                        .notification_durations,
                                      form.UEAV_renew_agrement_licence
                                        .meet_tech,
                                      form.UEAV_renew_agrement_licence.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong>- Notifications diverses</strong>
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="6">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEAV_notification.control_form"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEAV_notification
                                        .notification_durations,
                                      form.UEAV_notification.control_form,
                                      form.UEAV_notification.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group
                                :label="$t('configuration.settings.meet_tech')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEAV_notification.meet_tech"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.meet_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEAV_notification
                                        .notification_durations,
                                      form.UEAV_notification.meet_tech,
                                      form.UEAV_notification.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                    </app-collapse-item>
                  </app-collapse>
                </div>

                <div>
                  <app-collapse>
                    <app-collapse-item
                      :title="'Guide de tourisme'"
                      class="brdr my-1"
                    >
                      <hr />
                      <div>
                        <b-card-header>
                          <strong>- Demande de carte professionnelle</strong>
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="6">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEGT_carte_professionnelle.control_form
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEGT_carte_professionnelle
                                        .notification_durations,
                                      form.UEGT_carte_professionnelle
                                        .control_form,
                                      form.UEGT_carte_professionnelle.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group
                                :label="$t('configuration.settings.meet_tech')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEGT_carte_professionnelle.meet_tech
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.meet_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEGT_carte_professionnelle
                                        .notification_durations,
                                      form.UEGT_carte_professionnelle.meet_tech,
                                      form.UEGT_carte_professionnelle.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong
                            >- Demande de renouvellement de cartes
                            professionnelle</strong
                          >
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="6">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEGT_renew_carte_professionnelle
                                      .control_form
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEGT_renew_carte_professionnelle
                                        .notification_durations,
                                      form.UEGT_renew_carte_professionnelle
                                        .control_form,
                                      form.UEGT_renew_carte_professionnelle.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group
                                :label="$t('configuration.settings.meet_tech')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEGT_renew_carte_professionnelle
                                      .meet_tech
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.meet_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEGT_renew_carte_professionnelle
                                        .notification_durations,
                                      form.UEGT_renew_carte_professionnelle
                                        .meet_tech,
                                      form.UEGT_renew_carte_professionnelle.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong>- Demande de changement d’affectation</strong>
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="6">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEGT_changement_affectation
                                      .control_form
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEGT_changement_affectation
                                        .notification_durations,
                                      form.UEGT_changement_affectation
                                        .control_form,
                                      form.UEGT_changement_affectation.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group
                                :label="$t('configuration.settings.meet_tech')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEGT_changement_affectation.meet_tech
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.meet_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEGT_changement_affectation
                                        .notification_durations,
                                      form.UEGT_changement_affectation
                                        .meet_tech,
                                      form.UEGT_changement_affectation.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong>- Demande d’ajout d’une spécialité</strong>
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="6">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEGT_ajout_specialite.control_form
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEGT_ajout_specialite
                                        .notification_durations,
                                      form.UEGT_ajout_specialite.control_form,
                                      form.UEGT_ajout_specialite.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group
                                :label="$t('configuration.settings.meet_tech')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UEGT_ajout_specialite.meet_tech"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.meet_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEGT_ajout_specialite
                                        .notification_durations,
                                      form.UEGT_ajout_specialite.meet_tech,
                                      form.UEGT_ajout_specialite.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong
                            >- Demande d’exercice au-delà de 60 ans</strong
                          >
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="6">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEGT_exercice_au_dela_60.control_form
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEGT_exercice_au_dela_60
                                        .notification_durations,
                                      form.UEGT_exercice_au_dela_60
                                        .control_form,
                                      form.UEGT_exercice_au_dela_60.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group
                                :label="$t('configuration.settings.meet_tech')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UEGT_exercice_au_dela_60.meet_tech
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.meet_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UEGT_exercice_au_dela_60
                                        .notification_durations,
                                      form.UEGT_exercice_au_dela_60.meet_tech,
                                      form.UEGT_exercice_au_dela_60.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                    </app-collapse-item>
                  </app-collapse>
                </div>

                <div>
                  <app-collapse>
                    <app-collapse-item
                      :title="'Etablissement de restauration et assimilés'"
                      class="brdr my-1"
                    >
                      <hr />
                      <div>
                        <b-card-header>
                          <strong
                            >- Demande d’autorisation d’exploitation</strong
                          >
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UER_autorisation.control_form"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_autorisation
                                        .notification_durations,
                                      form.UER_autorisation.control_form,
                                      form.UER_autorisation.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_tech')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UER_autorisation.control_tech"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_autorisation
                                        .notification_durations,
                                      form.UER_autorisation.control_tech,
                                      form.UER_autorisation.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="$t('configuration.settings.audit')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UER_autorisation.audit"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.audit_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_autorisation
                                        .notification_durations,
                                      form.UER_autorisation.audit,
                                      form.UER_autorisation.id,
                                      2
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong>- Demande de classement</strong>
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UER_classement.control_form"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_classement
                                        .notification_durations,
                                      form.UER_classement.control_form,
                                      form.UER_classement.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_tech')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UER_classement.control_tech"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_classement
                                        .notification_durations,
                                      form.UER_classement.control_tech,
                                      form.UER_classement.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="$t('configuration.settings.audit')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UER_classement.audit"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.audit_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_classement
                                        .notification_durations,
                                      form.UER_classement.audit,
                                      form.UER_classement.id,
                                      2
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong
                            >- Demande de renouvellement de
                            l’autorisation</strong
                          >
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UER_renew_autorisation.control_form
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_renew_autorisation
                                        .notification_durations,
                                      form.UER_renew_autorisation.control_form,
                                      form.UER_renew_autorisation.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_tech')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UER_renew_autorisation.control_tech
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_renew_autorisation
                                        .notification_durations,
                                      form.UER_renew_autorisation.control_tech,
                                      form.UER_renew_autorisation.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="$t('configuration.settings.audit')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UER_renew_autorisation.audit"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.audit_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_renew_autorisation
                                        .notification_durations,
                                      form.UER_renew_autorisation.audit,
                                      form.UER_renew_autorisation.id,
                                      2
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong
                            >- Demande de renouvellement du classement</strong
                          >
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UER_renew_classement.control_form
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_renew_classement
                                        .notification_durations,
                                      form.UER_renew_classement.control_form,
                                      form.UER_renew_classement.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_tech')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="
                                    form.UER_renew_classement.control_tech
                                  "
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_renew_classement
                                        .notification_durations,
                                      form.UER_renew_classement.control_tech,
                                      form.UER_renew_classement.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="$t('configuration.settings.audit')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UER_renew_classement.audit"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.audit_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_renew_classement
                                        .notification_durations,
                                      form.UER_renew_classement.audit,
                                      form.UER_renew_classement.id,
                                      2
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                      <div>
                        <b-card-header>
                          <strong>- Notifications diverses</strong>
                        </b-card-header>
                        <b-card-body>
                          <b-row>
                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_form')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UER_notification.control_form"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_form_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_notification
                                        .notification_durations,
                                      form.UER_notification.control_form,
                                      form.UER_notification.id,
                                      0
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="
                                  $t('configuration.settings.control_tech')
                                "
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UER_notification.control_tech"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.control_tech_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_notification
                                        .notification_durations,
                                      form.UER_notification.control_tech,
                                      form.UER_notification.id,
                                      1
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>

                            <b-col md="4">
                              <b-form-group
                                :label="$t('configuration.settings.audit')"
                                label-cols-md="12"
                                label-for="title"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="form.UER_notification.audit"
                                  :placeholder="
                                    $t(
                                      'configuration.settings.audit_placeholder'
                                    )
                                  "
                                  type="number"
                                  @change="
                                    updateTypesDemande(
                                      form.UER_notification
                                        .notification_durations,
                                      form.UER_notification.audit,
                                      form.UER_notification.id,
                                      2
                                    )
                                  "
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </div>
                    </app-collapse-item>
                  </app-collapse>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
        <!-- <div class="d-flex flex-row-reverse mt-1">
        <b-button
          variant="primary"
          @click="updateConfigurations()"
          :disabled="loadingUpdateConfig == true"
        >
          <feather-icon
            v-if="loadingUpdateConfig == false"
            icon="SaveIcon"
          /><b-spinner v-if="loadingUpdateConfig == true" small /> &nbsp;
          {{ $t("button.edit_form") }}
        </b-button>
      </div> -->
      </b-card>
    </div>

    <!--   <b-card>
      <b-card-header class="p-0">
        <h4>{{ $t('configuration.settings.agregator_key_title') }}</h4>
      </b-card-header>
      <hr>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="FedaPay"
              label-cols-md="12"
              label-for="fedapay"
            >
              <b-form-input
                id="fedapay"
                v-model="form.fedapay_key"
                :placeholder="$t('configuration.settings.fedapay_input_placeholder')"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="KKiaPay"
              label-cols-md="12"
              label-for="kkiapay"
            >
              <b-form-input
                id="kkiapay"
                v-model="form.kkiapay_key"
                :placeholder="$t('configuration.settings.kkiapay_input_placeholder')"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <div class="d-flex flex-row-reverse mt-1">

        <b-button
          variant="primary"
          @click="updateConfigurations()" :disabled="loadingUpdateConfig"
        >
          <feather-icon
            v-if="loadingUpdateConfig == false"
            icon="SaveIcon"
          /><b-spinner
            v-if="loadingUpdateConfig == true"
            small
          /> &nbsp; {{ $t('button.edit_form')}}
        </b-button>
      </div>
    </b-card> -->
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BModal,
  VBModal,
  VBToggle,
  BAlert,
  BFormRadioGroup,
  BSpinner,
  BCardHeader,
  BCardBody,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { VueEditor } from 'vue2-editor'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// ---------------------------- store modules and vuex utilities
import appConfigurationStroreModule from '@/store/app-configuration'
import typeDemamdesStoreModule from '@/store/type-demandes'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import utilsService from '@/services/utils/utils.service'

import {
  registerStoreModule,
  // unregisterStoreModule,
} from '@/helpers/vuex-utils'
import index from 'vue-prism-component'

export default {
  components: {
    quillEditor,
    BTable,
    BCard,
    BBadge,
    BRow,
    BCol,
    flatPickr,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BModal,
    VBModal,
    BAlert,
    BSpinner,
    BCardText,
    BFormRadioGroup,
    BCardHeader,

    BTabs,
    BTab,
    BCardBody,
    VueEditor,
    ToastificationContent,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    const { isAdmin } = utilsService.currentUserUtils()
    return {
      configs: [
        // {
        //  id: null,
        //  code: "login.configuration.enable_otp",
        // value: false,
        // },
        {
          id: null,
          code: 'concours.configuration.enable',
          value: false,
        },
        {
          id: null,
          code: 'config.fedapay.key',
          value: '',
        },
        {
          id: null,
          code: 'config.kkiapay.key',
          value: '',
        },
        // UEH
        {
          id: null,
          code: 'config.configuration.UEH_autorisation_premier_classement',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UEH_reclassement',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UEH_notification',
          value: '',
        },

        // UEAV
        {
          id: null,
          code: 'config.configuration.UEAV_licence',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UEAV_agrement',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UEAV_renew_agrement_licence',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UEAV_notification',
          value: '',
        },
        // UEGT
        {
          id: null,
          code: 'config.configuration.UEGT_carte_professionnelle',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UEGT_renew_carte_professionnelle',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UEGT_changement_affectation',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UEGT_ajout_specialite',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UEGT_exercice_au_dela_60',
          value: '',
        },
        // UER
        {
          id: null,
          code: 'config.configuration.UER_autorisation',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UER_classement',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UER_renew_autorisation',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UER_renew_classemen',
          value: '',
        },
        {
          id: null,
          code: 'config.configuration.UER_notification',
          value: '',
        },
      ],
      loadingUpdateConfig: false,
      isFetchingSecteur: false,
      listSecteurs: [],
      heureDebutVisite: null,
      heureFinVisite: null,
      isAdmin,
      form: {
        // accept: false,
        fedapay_key: '',
        kkiapay_key: '',
        concours: false,

        // UEH ----------
        UEH_autorisation_premier_classement: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          control_tech: null,
          audit: null,
        },
        UEH_reclassement: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          control_tech: null,
          audit: null,
        },
        UEH_notification: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          control_tech: null,
          audit: null,
        },

        // UEAV ----------

        UEAV_licence: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          meet_tech: null,
        },
        UEAV_agrement: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          meet_tech: null,
        },
        UEAV_renew_agrement_licence: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          meet_tech: null,
        },
        UEAV_notification: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          meet_tech: null,
        },

        // UEGT ----------
        UEGT_carte_professionnelle: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          meet_tech: null,
        },
        UEGT_renew_carte_professionnelle: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          meet_tech: null,
        },
        UEGT_changement_affectation: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          meet_tech: null,
        },
        UEGT_ajout_specialite: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          meet_tech: null,
        },
        UEGT_exercice_au_dela_60: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          meet_tech: null,
        },
        // UER ----------
        UER_autorisation: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          control_tech: null,
          audit: null,
        },

        UER_classement: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          control_tech: null,
          audit: null,
        },
        UER_renew_autorisation: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          control_tech: null,
          audit: null,
        },
        UER_renew_classement: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          control_tech: null,
          audit: null,
        },
        UER_notification: {
          id: null,
          index_notif: null,
          notification_durations: null,
          control_form: null,
          control_tech: null,
          audit: null,
        },
      },
      listTypesDemandes: [],
      isFetchingTypeDemande: false,
      selectedOption: '',
      snowOption: {
        theme: 'snow',
      },
      editorOption: {
        theme: 'snow',
        // modules: {
        //   toolbar: '#quill-toolbar',
        // },
        placeholder: '',
      },
    }
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.fetchTypesDemandes()
        this.loadDataAppConfiguration()
      },
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: 'app-configurations', module: appConfigurationStroreModule },
      { path: 'type-demandes', module: typeDemamdesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  mounted() {},
  methods: {
    ...mapMutations('app-configurations', {
      action_setter_passForgotAccess: 'setter_passForgotAccess',
    }),
    // import actions
    ...mapActions('app-configurations', {
      action_getAppConfiguration: 'getAppConfiguration',
      action_searchAppConfiguration: 'searchAppConfiguration',
      action_updateAppConfiguration: 'updateAppConfiguration',
    }),
    ...mapActions('secteurs', {
      action_fetchSecteurs: 'fetchSecteurs',
    }),
    ...mapActions('type-demandes', {
      action_fetchTypesDemandes: 'fetchTypeDemandes',
      action_updateTypeDemandes: 'updateTypeDemandes',
    }),
    // -----------------------------------------------------------------------

    async loadDataAppConfiguration() {
      this.loadingUpdateConfig = true
      this.configs.forEach(async (config, index) => {
        this.action_searchAppConfiguration({ code: config.code })
          .then((response) => {
            if (response.status === 200) {
              this.configs[index].value = response.data.data[0].value
              this.configs[index].id = response.data.data[0].id
              // if (index === 0) this.form.accept = this.configs[index].value;
              if (index === 0) {
                this.form.concours = this.configs[index].value
              }
              // if (index === 1) {
              //   this.form.fedapay_key = this.configs[index].value;
              // }
              // if (index === 2)
              //   this.form.kkiapay_key = this.configs[index].value;

              // // UEH

              // if (index === 3) {
              //   this.form.UEH_autorisation_premier_classement.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEH_autorisation_premier_classement.control_tech =
              //     this.configs[index].value[1];
              //   this.form.UEH_autorisation_premier_classement.audit =
              //     this.configs[index].value[2];
              // }
              // if (index === 4) {
              //   this.form.UEH_reclassement.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEH_reclassement.control_tech =
              //     this.configs[index].value[1];
              //   this.form.UEH_reclassement.audit = this.configs[index].value[2];
              // }
              // if (index === 5) {
              //   this.form.UEH_notification.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEH_notification.control_tech =
              //     this.configs[index].value[1];
              //   this.form.UEH_notification.audit = this.configs[index].value[2];
              // }

              // // UEAV

              // if (index === 6) {
              //   this.form.UEAV_licence.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEAV_licence.meet_tech = this.configs[index].value[1];
              // }
              // if (index === 7) {
              //   this.form.UEAV_agrement.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEAV_agrement.meet_tech =
              //     this.configs[index].value[1];
              // }
              // if (index === 8) {
              //   this.form.UEAV_renew_agrement_licence.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEAV_renew_agrement_licence.meet_tech =
              //     this.configs[index].value[1];
              // }
              // if (index === 9) {
              //   this.form.UEAV_notification.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEAV_notification.meet_tech =
              //     this.configs[index].value[1];
              // }

              // // UEGT

              // if (index === 10) {
              //   this.form.UEGT_carte_professionnelle.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEGT_carte_professionnelle.meet_tech =
              //     this.configs[index].value[1];
              // }
              // if (index === 11) {
              //   this.form.UEGT_renew_carte_professionnelle.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEGT_renew_carte_professionnelle.meet_tech =
              //     this.configs[index].value[1];
              // }
              // if (index === 12) {
              //   this.form.UEGT_changement_affectation.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEGT_changement_affectation.meet_tech =
              //     this.configs[index].value[1];
              // }
              // if (index === 13) {
              //   this.form.UEGT_ajout_specialite.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEGT_ajout_specialite.meet_tech =
              //     this.configs[index].value[1];
              // }
              // if (index === 14) {
              //   this.form.UEGT_exercice_au_dela_60.control_form =
              //     this.configs[index].value[0];
              //   this.form.UEGT_exercice_au_dela_60.meet_tech =
              //     this.configs[index].value[1];
              // }

              // // UER

              // if (index === 15) {
              //   this.form.UER_autorisation.control_form =
              //     this.configs[index].value[0];
              //   this.form.UER_autorisation.control_tech =
              //     this.configs[index].value[1];
              //   this.form.UER_autorisation.audit = this.configs[index].value[2];
              // }
              // if (index === 16) {
              //   this.form.UER_classement.control_form =
              //     this.configs[index].value[0];
              //   this.form.UER_classement.control_tech =
              //     this.configs[index].value[1];
              //   this.form.UER_classement.audit = this.configs[index].value[2];
              // }
              // if (index === 17) {
              //   this.form.UER_renew_autorisation.control_form =
              //     this.configs[index].value[0];
              //   this.form.UER_renew_autorisation.control_tech =
              //     this.configs[index].value[1];
              //   this.form.UER_renew_autorisation.audit =
              //     this.configs[index].value[2];
              // }
              // if (index === 18) {
              //   this.form.UER_renew_classement.control_form =
              //     this.configs[index].value[0];
              //   this.form.UER_renew_classement.control_tech =
              //     this.configs[index].value[1];
              //   this.form.UER_renew_classement.audit =
              //     this.configs[index].value[2];
              // }
              // if (index === 19) {
              //   this.form.UER_notification.control_form =
              //     this.configs[index].value[0];
              //   this.form.UER_notification.control_tech =
              //     this.configs[index].value[1];
              //   this.form.UER_notification.audit = this.configs[index].value[2];
              // }
            }
            this.loadingUpdateConfig = false
          })
          .catch((err) => {
            this.loadingUpdateConfig = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.message || 'Une erreur est survenue',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      })
    },

    async updateConfigurations() {
      this.loadingUpdateConfig = true

      //   this.configs.map((e) => (e.value = e.value.toString()));
      let haveError = false
      for (let index = 0; index < this.configs.length; index++) {
        const config = this.configs[index]
        // if (index === 0) config.value = this.form.accept;
        if (index === 0) config.value = this.form.concours
        // if (index === 1) config.value = this.form.fedapay_key;
        // if (index === 2) config.value = this.form.kkiapay_key;
        // UEH_autorisation_premier_classement
        // if (index === 3)
        //   config.value =
        //     "" +
        //     this.form.UEH_autorisation_premier_classement.control_form +
        //     "," +
        //     this.form.UEH_autorisation_premier_classement.control_tech +
        //     "," +
        //     this.form.UEH_autorisation_premier_classement.audit;

        // // UEH_reclassement
        // if (index === 4)
        //   config.value =
        //     "" +
        //     this.form.UEH_reclassement.control_form +
        //     "," +
        //     this.form.UEH_reclassement.control_tech +
        //     "," +
        //     this.form.UEH_reclassement.audit;

        // // UEH_notification
        // if (index === 5)
        //   config.value =
        //     "" +
        //     this.form.UEH_notification.control_form +
        //     "," +
        //     this.form.UEH_notification.control_tech +
        //     "," +
        //     this.form.UEH_notification.audit;

        // if (index === 6)
        //   config.value =
        //     "" +
        //     this.form.UEAV_licence.control_form +
        //     "," +
        //     this.form.UEAV_licence.meet_tech;

        // if (index === 7)
        //   config.value =
        //     "" +
        //     this.form.UEAV_agrement.control_form +
        //     "," +
        //     this.form.UEAV_agrement.meet_tech;

        // if (index === 8)
        //   config.value =
        //     "" +
        //     this.form.UEAV_renew_agrement_licence.control_form +
        //     "," +
        //     this.form.UEAV_renew_agrement_licence.meet_tech;

        // if (index === 9)
        //   config.value =
        //     "" +
        //     this.form.UEAV_notification.control_form +
        //     "," +
        //     this.form.UEAV_notification.meet_tech;

        // if (index === 10)
        //   config.value =
        //     "" +
        //     this.form.UEGT_carte_professionnelle.control_form +
        //     "," +
        //     this.form.UEGT_carte_professionnelle.meet_tech;

        // if (index === 11)
        //   config.value =
        //     "" +
        //     this.form.UEGT_renew_carte_professionnelle.control_form +
        //     "," +
        //     this.form.UEGT_renew_carte_professionnelle.meet_tech;

        // if (index === 12)
        //   config.value =
        //     "" +
        //     this.form.UEGT_changement_affectation.control_form +
        //     "," +
        //     this.form.UEGT_changement_affectation.meet_tech;

        // if (index === 13)
        //   config.value =
        //     "" +
        //     this.form.UEGT_ajout_specialite.control_form +
        //     "," +
        //     this.form.UEGT_ajout_specialite.meet_tech;

        // if (index === 14)
        //   config.value =
        //     "" +
        //     this.form.UEGT_exercice_au_dela_60.control_form +
        //     "," +
        //     this.form.UEGT_exercice_au_dela_60.meet_tech;

        // if (index === 15)
        //   config.value =
        //     "" +
        //     this.form.UER_autorisation.control_form +
        //     "," +
        //     this.form.UER_autorisation.control_tech +
        //     "," +
        //     this.form.UER_autorisation.audit;

        // if (index === 16)
        //   config.value =
        //     "" +
        //     this.form.UER_classement.control_form +
        //     "," +
        //     this.form.UER_classement.control_tech +
        //     "," +
        //     this.form.UER_classement.audit;

        // if (index === 17)
        //   config.value =
        //     "" +
        //     this.form.UER_renew_autorisation.control_form +
        //     "," +
        //     this.form.UER_renew_autorisation.control_tech +
        //     "," +
        //     this.form.UER_renew_autorisation.audit;

        // if (index === 18)
        //   config.value =
        //     "" +
        //     this.form.UER_renew_classement.control_form +
        //     "," +
        //     this.form.UER_renew_classement.control_tech +
        //     "," +
        //     this.form.UER_renew_classement.audit;

        // if (index === 19)
        //   config.value =
        //     "" +
        //     this.form.UER_notification.control_form +
        //     "," +
        //     this.form.UER_notification.control_tech +
        //     "," +
        //     this.form.UER_notification.audit;

        //  config.value.toString();
        const { id, code, value } = config
        // eslint-disable-next-line no-await-in-loop
        await this.action_updateAppConfiguration({ id, data: { code, value } })
          .then((response) => {
            // console.log('response: ', response)
          })
          // eslint-disable-next-line no-loop-func
          .catch((err) => {
            console.log('err: ', err)
            haveError = true
          })
      }
      this.loadingUpdateConfig = false
      if (!haveError) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Opération réussie',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
      }
    },

    fetchTypesDemandes() {
      this.isFetchingTypeDemande = true
      this.action_fetchTypesDemandes()
        .then((response) => {
          this.isFetchingTypeDemande = false
          this.listTypesDemandes = response.data.data
          // console.log("this.formload11111: ", this.listTypesDemandes);
          this.listTypesDemandes.map((e) => {
            if (
              e.notification_durations != null &&
              e.notification_durations.length > 0
            ) {
              // UEH
              if (e.code === 'UEH_autorisation_premier_classement') {
                this.form.UEH_autorisation_premier_classement.control_form =
                  e.notification_durations[0].duration
                this.form.UEH_autorisation_premier_classement.control_tech =
                  e.notification_durations[1].duration

                this.form.UEH_autorisation_premier_classement.audit =
                  e.notification_durations[2].duration
                this.form.UEH_autorisation_premier_classement.notification_durations =
                  e.notification_durations
                this.form.UEH_autorisation_premier_classement.id = e.id
              }
              if (e.code === 'UEH_reclassement') {
                this.form.UEH_reclassement.control_form =
                  e.notification_durations[0].duration
                this.form.UEH_reclassement.control_tech =
                  e.notification_durations[1].duration
                this.form.UEH_reclassement.audit =
                  e.notification_durations[2].duration
                this.form.UEH_reclassement.id = e.id
                this.form.UEH_autorisation_premier_classement.notification_durations =
                  e.notification_durations
              }
              if (e.code === 'UEH_notification') {
                this.form.UEH_notification.control_form =
                  e.notification_durations[0].duration
                this.form.UEH_notification.control_tech =
                  e.notification_durations[1].duration
                this.form.UEH_notification.audit =
                  e.notification_durations[2].duration
                this.form.UEH_notification.id = e.id
                this.form.UEH_autorisation_premier_classement.notification_durations =
                  e.notification_durations
              }

              // UEAV
              if (e.code === 'UEAV_licence') {
                this.form.UEAV_licence.control_form =
                  e.notification_durations[0].duration
                this.form.UEAV_licence.meet_tech =
                  e.notification_durations[1].duration

                this.form.UEAV_licence.id = e.id
              }

              if (e.code === 'UEAV_agrement') {
                this.form.UEAV_agrement.control_form =
                  e.notification_durations[0].duration
                this.form.UEAV_agrement.meet_tech =
                  e.notification_durations[1].duration

                this.form.UEAV_agrement.id = e.id
              }

              if (e.code === 'UEAV_renew_agrement_licence') {
                this.form.UEAV_renew_agrement_licence.control_form =
                  e.notification_durations[0].duration
                this.form.UEAV_renew_agrement_licence.meet_tech =
                  e.notification_durations[1].duration

                this.form.UEAV_renew_agrement_licence.id = e.id
              }

              if (e.code === 'UEAV_notification') {
                this.form.UEAV_notification.control_form =
                  e.notification_durations[0].duration
                this.form.UEAV_notification.meet_tech =
                  e.notification_durations[1].duration

                this.form.UEAV_notification.id = e.id
              }

              // UEGT

              if (e.code === 'UEGT_carte_professionnelle') {
                this.form.UEGT_carte_professionnelle.control_form =
                  e.notification_durations[0].duration
                this.form.UEGT_carte_professionnelle.meet_tech =
                  e.notification_durations[1].duration

                this.form.UEGT_carte_professionnelle.id = e.id
              }

              if (e.code === 'UEGT_renew_carte_professionnelle') {
                this.form.UEGT_renew_carte_professionnelle.control_form =
                  e.notification_durations[0].duration
                this.form.UEGT_renew_carte_professionnelle.meet_tech =
                  e.notification_durations[1].duration

                this.form.UEGT_renew_carte_professionnelle.id = e.id
              }

              if (e.code === 'UEGT_changement_affectation') {
                this.form.UEGT_changement_affectation.control_form =
                  e.notification_durations[0].duration
                this.form.UEGT_changement_affectation.meet_tech =
                  e.notification_durations[1].duration

                this.form.UEGT_changement_affectation.id = e.id
              }

              if (e.code === 'UEGT_ajout_specialite') {
                this.form.UEGT_ajout_specialite.control_form =
                  e.notification_durations[0].duration
                this.form.UEGT_ajout_specialite.meet_tech =
                  e.notification_durations[1].duration

                this.form.UEGT_ajout_specialite.id = e.id
              }
              if (e.code === 'UEGT_exercice_au_dela_60') {
                this.form.UEGT_exercice_au_dela_60.control_form =
                  e.notification_durations[0].duration
                this.form.UEGT_exercice_au_dela_60.meet_tech =
                  e.notification_durations[1].duration

                this.form.UEGT_exercice_au_dela_60.id = e.id
              }
              // UER

              if (e.code === 'UER_autorisation') {
                this.form.UER_autorisation.control_form =
                  e.notification_durations[0].duration
                this.form.UER_autorisation.control_tech =
                  e.UER_autorisation[1].duration

                this.form.UER_autorisation.audit =
                  e.notification_durations[2].duration

                this.form.UER_autorisation.id = e.id
              }
              if (e.code === 'UER_classement') {
                this.form.UER_classement.control_form =
                  e.notification_durations[0].duration
                this.form.UER_classement.control_tech =
                  e.notification_durations[1].duration
                this.form.UER_classement.audit =
                  e.notification_durations[2].duration
                this.form.UER_classement.id = e.id
              }
              if (e.code === 'UER_renew_autorisation') {
                this.form.UER_renew_autorisation.control_form =
                  e.notification_durations[0].duration
                this.form.UER_renew_autorisation.control_tech =
                  e.notification_durations[1].duration
                this.form.UER_renew_autorisation.audit =
                  e.notification_durations[2].duration
                this.form.UER_renew_autorisation.id = e.id
              }
              if (e.code === 'UER_renew_classement') {
                this.form.UER_renew_classement.control_form =
                  e.notification_durations[0].duration
                this.form.UER_renew_classement.control_tech =
                  e.notification_durations[1].duration

                this.form.UER_renew_classement.audit =
                  e.notification_durations[2].duration

                this.form.UER_renew_classement.id = e.id
              }
              if (e.code === 'UER_notification') {
                this.form.UER_notification.control_form =
                  e.notification_durations[0].duration
                this.form.UER_notification.control_tech =
                  e.notification_durations[1].duration
                this.form.UER_notification.audit =
                  e.notification_durations[2].duration
                this.form.UER_notification.id = e.id
              }
            }
          })
          // console.log('this.formload1111122: ', this.listTypesDemandes)
        })
        .catch((error) => {
          this.isFetchingTypeDemande = false
        })
    },

    updateTypesDemande(data, duration, id, index_notif) {
      console.log('log---------'), (this.isFetchingTypeDemande = true)
      const notif = data
      if (data != null) {
        console.log('notif: ', notif)
        notif[index_notif].duration = parseInt(duration)
      }
      const finalData = {
        notification_durations: notif,
      }
      this.action_updateTypeDemandes({
        id,
        data: finalData,
      })
        .then((response) => {
          this.isFetchingTypeDemande = false

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Durée mise a jour avec succès',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: '',
            },
          })
        })

        .catch((error) => {
          // console.log(error)
          this.isFetchingTypeDemande = false
          // this.errored = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Une erreur est survenue',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: '',
            },
          })
        })
    },
  },
}
</script>
<style scoped>
.brdr {
  border-radius: 0.5rem;

  border: 1px solid #d9d9d9 !important;
}

::v-deep .nav-pills {
  margin-bottom: 0px;
  margin-left: 1rem;
  margin-right: 1rem;
}

::v-deep .nav-pills .nav-link {
  color: white !important;
}

::v-deep .nav-pills .nav-link.active {
  color: #000000 !important;
}
</style>
