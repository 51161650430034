var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"invoice-preview-card"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('h2',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm.$t('configuration.settings.title'))),_c('br')])])]),(!_vm.isAdmin)?_c('div',[_c('h3',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('configuration.settings.no_access')))])]):_c('div',[_c('b-card',[_c('b-card-header',{staticClass:"p-0"},[_c('h4',[_vm._v(_vm._s(_vm.$t('configuration.settings.concours_title')))])]),_c('hr'),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',[_c('b-form-checkbox',{attrs:{"variant":"primary","switch":""},model:{value:(_vm.form.concours),callback:function ($$v) {_vm.$set(_vm.form, "concours", $$v)},expression:"form.concours"}},[_vm._v(" "+_vm._s(_vm.form.concours && _vm.form.concours == true ? _vm.$t('configuration.settings.concours_checkbox_disabled') : _vm.$t('configuration.settings.concours_checkbox_enabled'))+" ")])],1)])],1)],1),_c('div',{staticClass:"d-flex flex-row-reverse mt-1"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loadingUpdateConfig == true},on:{"click":function($event){return _vm.updateConfigurations()}}},[(_vm.loadingUpdateConfig == false)?_c('feather-icon',{attrs:{"icon":"SaveIcon"}}):_vm._e(),(_vm.loadingUpdateConfig == true)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v("   "+_vm._s(_vm.$t('button.edit_form'))+" ")],1)],1)],1),_c('b-card',[_c('b-card-header',{staticClass:"p-0"},[_c('h4',[_vm._v("Configuration des horaires de visite")])]),_c('hr'),_c('b-card-body',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-group',{attrs:{"label":"Heure de Début","label-cols-sm":"5","label-cols-lg":"5"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Veuillez saisir l’heure de début de la visite","config":{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                minuteIncrement: 1,
              }},model:{value:(_vm.heureDebutVisite),callback:function ($$v) {_vm.heureDebutVisite=$$v},expression:"heureDebutVisite"}})],1),_c('b-form-group',{staticClass:"ml-2",attrs:{"label":"Heure de Fin","label-cols-sm":"5","label-cols-lg":"5"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Veuillez saisir l’heure de début de la visite","config":{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                minuteIncrement: 1,
              }},model:{value:(_vm.heureFinVisite),callback:function ($$v) {_vm.heureFinVisite=$$v},expression:"heureFinVisite"}})],1)],1)])],1),_c('b-card',[_c('b-card-header',{staticClass:"p-0"},[_c('h4',[_vm._v(_vm._s(_vm.$t('configuration.settings.delay_title')))])]),_c('hr'),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',[_c('div',[_c('app-collapse',[_c('app-collapse-item',{staticClass:"brdr my-1",attrs:{"title":'Etablissement d\'hébergement touristique'}},[_c('hr'),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande d’autorisation d’exploitation et de premier classement")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEH_autorisation_premier_classement
                                      .notification_durations,
                                    _vm.form.UEH_autorisation_premier_classement
                                      .control_form,
                                    _vm.form.UEH_autorisation_premier_classement
                                      .id,
                                    0
                                  )}},model:{value:(
                                  _vm.form.UEH_autorisation_premier_classement
                                    .control_form
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEH_autorisation_premier_classement
                                    , "control_form", $$v)},expression:"\n                                  form.UEH_autorisation_premier_classement\n                                    .control_form\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEH_autorisation_premier_classement
                                      .notification_durations,
                                    _vm.form.UEH_autorisation_premier_classement
                                      .control_tech,
                                    _vm.form.UEH_autorisation_premier_classement
                                      .id,
                                    1
                                  )}},model:{value:(
                                  _vm.form.UEH_autorisation_premier_classement
                                    .control_tech
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEH_autorisation_premier_classement
                                    , "control_tech", $$v)},expression:"\n                                  form.UEH_autorisation_premier_classement\n                                    .control_tech\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.audit'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.audit_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEH_autorisation_premier_classement
                                      .notification_durations,
                                    _vm.form.UEH_autorisation_premier_classement
                                      .audit,
                                    _vm.form.UEH_autorisation_premier_classement
                                      .id,
                                    2
                                  )}},model:{value:(
                                  _vm.form.UEH_autorisation_premier_classement
                                    .audit
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEH_autorisation_premier_classement
                                    , "audit", $$v)},expression:"\n                                  form.UEH_autorisation_premier_classement\n                                    .audit\n                                "}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande de reclassement")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEH_reclassement
                                      .notification_durations,
                                    _vm.form.UEH_reclassement.control_form,
                                    _vm.form.UEH_reclassement.id,
                                    0
                                  )}},model:{value:(_vm.form.UEH_reclassement.control_form),callback:function ($$v) {_vm.$set(_vm.form.UEH_reclassement, "control_form", $$v)},expression:"form.UEH_reclassement.control_form"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEH_reclassement
                                      .notification_durations,
                                    _vm.form.UEH_reclassement.control_tech,
                                    _vm.form.UEH_reclassement.id,
                                    1
                                  )}},model:{value:(_vm.form.UEH_reclassement.control_tech),callback:function ($$v) {_vm.$set(_vm.form.UEH_reclassement, "control_tech", $$v)},expression:"form.UEH_reclassement.control_tech"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.audit'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.audit_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEH_reclassement
                                      .notification_durations,
                                    _vm.form.UEH_reclassement.audit,
                                    _vm.form.UEH_reclassement.id,
                                    2
                                  )}},model:{value:(_vm.form.UEH_reclassement.audit),callback:function ($$v) {_vm.$set(_vm.form.UEH_reclassement, "audit", $$v)},expression:"form.UEH_reclassement.audit"}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Notifications diverses")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEH_notification
                                      .notification_durations,
                                    _vm.form.UEH_notification.control_form,
                                    _vm.form.UEH_notification.id,
                                    0
                                  )}},model:{value:(_vm.form.UEH_notification.control_form),callback:function ($$v) {_vm.$set(_vm.form.UEH_notification, "control_form", $$v)},expression:"form.UEH_notification.control_form"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEH_notification
                                      .notification_durations,
                                    _vm.form.UEH_notification.control_tech,
                                    _vm.form.UEH_notification.id,
                                    1
                                  )}},model:{value:(_vm.form.UEH_notification.control_tech),callback:function ($$v) {_vm.$set(_vm.form.UEH_notification, "control_tech", $$v)},expression:"form.UEH_notification.control_tech"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.audit'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.audit_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEH_notification
                                      .notification_durations,
                                    _vm.form.UEH_notification.audit,
                                    _vm.form.UEH_notification.id,
                                    2
                                  )}},model:{value:(_vm.form.UEH_notification.audit),callback:function ($$v) {_vm.$set(_vm.form.UEH_notification, "audit", $$v)},expression:"form.UEH_notification.audit"}})],1)],1)],1)],1)],1)])],1)],1),_c('div',[_c('app-collapse',[_c('app-collapse-item',{staticClass:"brdr my-1",attrs:{"title":'Agence de Voyage'}},[_c('hr'),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande de licence")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEAV_licence.notification_durations,
                                    _vm.form.UEAV_licence.control_form,
                                    _vm.form.UEAV_licence.id,
                                    0
                                  )}},model:{value:(_vm.form.UEAV_licence.control_form),callback:function ($$v) {_vm.$set(_vm.form.UEAV_licence, "control_form", $$v)},expression:"form.UEAV_licence.control_form"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.meet_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.meet_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEAV_licence.notification_durations,
                                    _vm.form.UEAV_licence.meet_tech,
                                    _vm.form.UEAV_licence.id,
                                    1
                                  )}},model:{value:(_vm.form.UEAV_licence.meet_tech),callback:function ($$v) {_vm.$set(_vm.form.UEAV_licence, "meet_tech", $$v)},expression:"form.UEAV_licence.meet_tech"}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande d’agrément de succursale")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEAV_agrement.notification_durations,
                                    _vm.form.UEAV_agrement.control_form,
                                    _vm.form.UEAV_agrement.id,
                                    0
                                  )}},model:{value:(_vm.form.UEAV_agrement.control_form),callback:function ($$v) {_vm.$set(_vm.form.UEAV_agrement, "control_form", $$v)},expression:"form.UEAV_agrement.control_form"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.meet_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.meet_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEAV_agrement.notification_durations,
                                    _vm.form.UEAV_agrement.meet_tech,
                                    _vm.form.UEAV_agrement.id,
                                    1
                                  )}},model:{value:(_vm.form.UEAV_agrement.meet_tech),callback:function ($$v) {_vm.$set(_vm.form.UEAV_agrement, "meet_tech", $$v)},expression:"form.UEAV_agrement.meet_tech"}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande de renouvellement de licence ou agrément de succursale")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEAV_renew_agrement_licence
                                      .notification_durations,
                                    _vm.form.UEAV_renew_agrement_licence
                                      .control_form,
                                    _vm.form.UEAV_renew_agrement_licence.id,
                                    0
                                  )}},model:{value:(
                                  _vm.form.UEAV_renew_agrement_licence
                                    .control_form
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEAV_renew_agrement_licence
                                    , "control_form", $$v)},expression:"\n                                  form.UEAV_renew_agrement_licence\n                                    .control_form\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.meet_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.meet_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEAV_renew_agrement_licence
                                      .notification_durations,
                                    _vm.form.UEAV_renew_agrement_licence
                                      .meet_tech,
                                    _vm.form.UEAV_renew_agrement_licence.id,
                                    1
                                  )}},model:{value:(
                                  _vm.form.UEAV_renew_agrement_licence.meet_tech
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEAV_renew_agrement_licence, "meet_tech", $$v)},expression:"\n                                  form.UEAV_renew_agrement_licence.meet_tech\n                                "}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Notifications diverses")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEAV_notification
                                      .notification_durations,
                                    _vm.form.UEAV_notification.control_form,
                                    _vm.form.UEAV_notification.id,
                                    0
                                  )}},model:{value:(_vm.form.UEAV_notification.control_form),callback:function ($$v) {_vm.$set(_vm.form.UEAV_notification, "control_form", $$v)},expression:"form.UEAV_notification.control_form"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.meet_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.meet_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEAV_notification
                                      .notification_durations,
                                    _vm.form.UEAV_notification.meet_tech,
                                    _vm.form.UEAV_notification.id,
                                    1
                                  )}},model:{value:(_vm.form.UEAV_notification.meet_tech),callback:function ($$v) {_vm.$set(_vm.form.UEAV_notification, "meet_tech", $$v)},expression:"form.UEAV_notification.meet_tech"}})],1)],1)],1)],1)],1)])],1)],1),_c('div',[_c('app-collapse',[_c('app-collapse-item',{staticClass:"brdr my-1",attrs:{"title":'Guide de tourisme'}},[_c('hr'),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande de carte professionnelle")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEGT_carte_professionnelle
                                      .notification_durations,
                                    _vm.form.UEGT_carte_professionnelle
                                      .control_form,
                                    _vm.form.UEGT_carte_professionnelle.id,
                                    0
                                  )}},model:{value:(
                                  _vm.form.UEGT_carte_professionnelle.control_form
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEGT_carte_professionnelle, "control_form", $$v)},expression:"\n                                  form.UEGT_carte_professionnelle.control_form\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.meet_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.meet_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEGT_carte_professionnelle
                                      .notification_durations,
                                    _vm.form.UEGT_carte_professionnelle.meet_tech,
                                    _vm.form.UEGT_carte_professionnelle.id,
                                    1
                                  )}},model:{value:(
                                  _vm.form.UEGT_carte_professionnelle.meet_tech
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEGT_carte_professionnelle, "meet_tech", $$v)},expression:"\n                                  form.UEGT_carte_professionnelle.meet_tech\n                                "}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande de renouvellement de cartes professionnelle")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEGT_renew_carte_professionnelle
                                      .notification_durations,
                                    _vm.form.UEGT_renew_carte_professionnelle
                                      .control_form,
                                    _vm.form.UEGT_renew_carte_professionnelle.id,
                                    0
                                  )}},model:{value:(
                                  _vm.form.UEGT_renew_carte_professionnelle
                                    .control_form
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEGT_renew_carte_professionnelle
                                    , "control_form", $$v)},expression:"\n                                  form.UEGT_renew_carte_professionnelle\n                                    .control_form\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.meet_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.meet_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEGT_renew_carte_professionnelle
                                      .notification_durations,
                                    _vm.form.UEGT_renew_carte_professionnelle
                                      .meet_tech,
                                    _vm.form.UEGT_renew_carte_professionnelle.id,
                                    1
                                  )}},model:{value:(
                                  _vm.form.UEGT_renew_carte_professionnelle
                                    .meet_tech
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEGT_renew_carte_professionnelle
                                    , "meet_tech", $$v)},expression:"\n                                  form.UEGT_renew_carte_professionnelle\n                                    .meet_tech\n                                "}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande de changement d’affectation")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEGT_changement_affectation
                                      .notification_durations,
                                    _vm.form.UEGT_changement_affectation
                                      .control_form,
                                    _vm.form.UEGT_changement_affectation.id,
                                    0
                                  )}},model:{value:(
                                  _vm.form.UEGT_changement_affectation
                                    .control_form
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEGT_changement_affectation
                                    , "control_form", $$v)},expression:"\n                                  form.UEGT_changement_affectation\n                                    .control_form\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.meet_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.meet_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEGT_changement_affectation
                                      .notification_durations,
                                    _vm.form.UEGT_changement_affectation
                                      .meet_tech,
                                    _vm.form.UEGT_changement_affectation.id,
                                    1
                                  )}},model:{value:(
                                  _vm.form.UEGT_changement_affectation.meet_tech
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEGT_changement_affectation, "meet_tech", $$v)},expression:"\n                                  form.UEGT_changement_affectation.meet_tech\n                                "}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande d’ajout d’une spécialité")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEGT_ajout_specialite
                                      .notification_durations,
                                    _vm.form.UEGT_ajout_specialite.control_form,
                                    _vm.form.UEGT_ajout_specialite.id,
                                    0
                                  )}},model:{value:(
                                  _vm.form.UEGT_ajout_specialite.control_form
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEGT_ajout_specialite, "control_form", $$v)},expression:"\n                                  form.UEGT_ajout_specialite.control_form\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.meet_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.meet_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEGT_ajout_specialite
                                      .notification_durations,
                                    _vm.form.UEGT_ajout_specialite.meet_tech,
                                    _vm.form.UEGT_ajout_specialite.id,
                                    1
                                  )}},model:{value:(_vm.form.UEGT_ajout_specialite.meet_tech),callback:function ($$v) {_vm.$set(_vm.form.UEGT_ajout_specialite, "meet_tech", $$v)},expression:"form.UEGT_ajout_specialite.meet_tech"}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande d’exercice au-delà de 60 ans")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEGT_exercice_au_dela_60
                                      .notification_durations,
                                    _vm.form.UEGT_exercice_au_dela_60
                                      .control_form,
                                    _vm.form.UEGT_exercice_au_dela_60.id,
                                    0
                                  )}},model:{value:(
                                  _vm.form.UEGT_exercice_au_dela_60.control_form
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEGT_exercice_au_dela_60, "control_form", $$v)},expression:"\n                                  form.UEGT_exercice_au_dela_60.control_form\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.meet_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.meet_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UEGT_exercice_au_dela_60
                                      .notification_durations,
                                    _vm.form.UEGT_exercice_au_dela_60.meet_tech,
                                    _vm.form.UEGT_exercice_au_dela_60.id,
                                    1
                                  )}},model:{value:(
                                  _vm.form.UEGT_exercice_au_dela_60.meet_tech
                                ),callback:function ($$v) {_vm.$set(_vm.form.UEGT_exercice_au_dela_60, "meet_tech", $$v)},expression:"\n                                  form.UEGT_exercice_au_dela_60.meet_tech\n                                "}})],1)],1)],1)],1)],1)])],1)],1),_c('div',[_c('app-collapse',[_c('app-collapse-item',{staticClass:"brdr my-1",attrs:{"title":'Etablissement de restauration et assimilés'}},[_c('hr'),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande d’autorisation d’exploitation")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_autorisation
                                      .notification_durations,
                                    _vm.form.UER_autorisation.control_form,
                                    _vm.form.UER_autorisation.id,
                                    0
                                  )}},model:{value:(_vm.form.UER_autorisation.control_form),callback:function ($$v) {_vm.$set(_vm.form.UER_autorisation, "control_form", $$v)},expression:"form.UER_autorisation.control_form"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_autorisation
                                      .notification_durations,
                                    _vm.form.UER_autorisation.control_tech,
                                    _vm.form.UER_autorisation.id,
                                    1
                                  )}},model:{value:(_vm.form.UER_autorisation.control_tech),callback:function ($$v) {_vm.$set(_vm.form.UER_autorisation, "control_tech", $$v)},expression:"form.UER_autorisation.control_tech"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.audit'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.audit_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_autorisation
                                      .notification_durations,
                                    _vm.form.UER_autorisation.audit,
                                    _vm.form.UER_autorisation.id,
                                    2
                                  )}},model:{value:(_vm.form.UER_autorisation.audit),callback:function ($$v) {_vm.$set(_vm.form.UER_autorisation, "audit", $$v)},expression:"form.UER_autorisation.audit"}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande de classement")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_classement
                                      .notification_durations,
                                    _vm.form.UER_classement.control_form,
                                    _vm.form.UER_classement.id,
                                    0
                                  )}},model:{value:(_vm.form.UER_classement.control_form),callback:function ($$v) {_vm.$set(_vm.form.UER_classement, "control_form", $$v)},expression:"form.UER_classement.control_form"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_classement
                                      .notification_durations,
                                    _vm.form.UER_classement.control_tech,
                                    _vm.form.UER_classement.id,
                                    1
                                  )}},model:{value:(_vm.form.UER_classement.control_tech),callback:function ($$v) {_vm.$set(_vm.form.UER_classement, "control_tech", $$v)},expression:"form.UER_classement.control_tech"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.audit'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.audit_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_classement
                                      .notification_durations,
                                    _vm.form.UER_classement.audit,
                                    _vm.form.UER_classement.id,
                                    2
                                  )}},model:{value:(_vm.form.UER_classement.audit),callback:function ($$v) {_vm.$set(_vm.form.UER_classement, "audit", $$v)},expression:"form.UER_classement.audit"}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande de renouvellement de l’autorisation")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_renew_autorisation
                                      .notification_durations,
                                    _vm.form.UER_renew_autorisation.control_form,
                                    _vm.form.UER_renew_autorisation.id,
                                    0
                                  )}},model:{value:(
                                  _vm.form.UER_renew_autorisation.control_form
                                ),callback:function ($$v) {_vm.$set(_vm.form.UER_renew_autorisation, "control_form", $$v)},expression:"\n                                  form.UER_renew_autorisation.control_form\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_renew_autorisation
                                      .notification_durations,
                                    _vm.form.UER_renew_autorisation.control_tech,
                                    _vm.form.UER_renew_autorisation.id,
                                    1
                                  )}},model:{value:(
                                  _vm.form.UER_renew_autorisation.control_tech
                                ),callback:function ($$v) {_vm.$set(_vm.form.UER_renew_autorisation, "control_tech", $$v)},expression:"\n                                  form.UER_renew_autorisation.control_tech\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.audit'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.audit_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_renew_autorisation
                                      .notification_durations,
                                    _vm.form.UER_renew_autorisation.audit,
                                    _vm.form.UER_renew_autorisation.id,
                                    2
                                  )}},model:{value:(_vm.form.UER_renew_autorisation.audit),callback:function ($$v) {_vm.$set(_vm.form.UER_renew_autorisation, "audit", $$v)},expression:"form.UER_renew_autorisation.audit"}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Demande de renouvellement du classement")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_renew_classement
                                      .notification_durations,
                                    _vm.form.UER_renew_classement.control_form,
                                    _vm.form.UER_renew_classement.id,
                                    0
                                  )}},model:{value:(
                                  _vm.form.UER_renew_classement.control_form
                                ),callback:function ($$v) {_vm.$set(_vm.form.UER_renew_classement, "control_form", $$v)},expression:"\n                                  form.UER_renew_classement.control_form\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_renew_classement
                                      .notification_durations,
                                    _vm.form.UER_renew_classement.control_tech,
                                    _vm.form.UER_renew_classement.id,
                                    1
                                  )}},model:{value:(
                                  _vm.form.UER_renew_classement.control_tech
                                ),callback:function ($$v) {_vm.$set(_vm.form.UER_renew_classement, "control_tech", $$v)},expression:"\n                                  form.UER_renew_classement.control_tech\n                                "}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.audit'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.audit_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_renew_classement
                                      .notification_durations,
                                    _vm.form.UER_renew_classement.audit,
                                    _vm.form.UER_renew_classement.id,
                                    2
                                  )}},model:{value:(_vm.form.UER_renew_classement.audit),callback:function ($$v) {_vm.$set(_vm.form.UER_renew_classement, "audit", $$v)},expression:"form.UER_renew_classement.audit"}})],1)],1)],1)],1)],1),_c('div',[_c('b-card-header',[_c('strong',[_vm._v("- Notifications diverses")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_form'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_form_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_notification
                                      .notification_durations,
                                    _vm.form.UER_notification.control_form,
                                    _vm.form.UER_notification.id,
                                    0
                                  )}},model:{value:(_vm.form.UER_notification.control_form),callback:function ($$v) {_vm.$set(_vm.form.UER_notification, "control_form", $$v)},expression:"form.UER_notification.control_form"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.control_tech'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.control_tech_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_notification
                                      .notification_durations,
                                    _vm.form.UER_notification.control_tech,
                                    _vm.form.UER_notification.id,
                                    1
                                  )}},model:{value:(_vm.form.UER_notification.control_tech),callback:function ($$v) {_vm.$set(_vm.form.UER_notification, "control_tech", $$v)},expression:"form.UER_notification.control_tech"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('configuration.settings.audit'),"label-cols-md":"12","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                                    'configuration.settings.audit_placeholder'
                                  ),"type":"number"},on:{"change":function($event){return _vm.updateTypesDemande(
                                    _vm.form.UER_notification
                                      .notification_durations,
                                    _vm.form.UER_notification.audit,
                                    _vm.form.UER_notification.id,
                                    2
                                  )}},model:{value:(_vm.form.UER_notification.audit),callback:function ($$v) {_vm.$set(_vm.form.UER_notification, "audit", $$v)},expression:"form.UER_notification.audit"}})],1)],1)],1)],1)],1)])],1)],1)])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }